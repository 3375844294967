import { toast } from "react-toastify";
import ApiClient from "./ApiClient";

const getAllBlogs = (paginationOptions) =>
  ApiClient.get(`blogs/get-blogs?${paginationOptions}`).catch(() =>
    toast.error("Unable to get the blogs")
  );

const getBlogBySlug = (slug) => ApiClient.get(`blogs/${slug}?isId=0`);

const getBlogById = (id) => ApiClient.get(`blogs/${id}?isId=1`);

const getLatestBlogs = (value) =>
  ApiClient.get(`blogs/get-latest-blogs/?take=4&&pageNo=1`).catch(() =>
    toast.error("Unable to get the blogs")
  );

const services = {
  getAllBlogs,
  getBlogById,
  getBlogBySlug,
  getLatestBlogs,
};

export default services;
