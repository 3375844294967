import {
  Box,
  Button,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BlogService from "services/BlogsService";
import useApi from "hooks/useApi";

import GlobalCareFinderMainImage from "assets/images/main/main-logo.png";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";
import CardSkeleton from "components/ui/skeletons/CardSkeleton";
import NoData from "components/ui/no-data/noData";

const AllBlogs = () => {
  const theme = useTheme();
  const getAllBlogsApi = useApi(BlogService.getAllBlogs);
  const [blogs, setBlogs] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const allBlogs = await getAllBlogsApi.request(
        `pageNo=${page + 1}&take=${rowsPerPage}`
      );
      if (allBlogs?.data?.response?.totalCount === 0) {
        setTotalCount(0);
        setBlogs(null);
      } else if (allBlogs?.data?.response?.totalCount) {
        setTotalCount(allBlogs.data.response.totalCount);
        setBlogs(allBlogs.data.response.blogs);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setBlogs(null);
      console.error("Failed to fetch blogs", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [page, rowsPerPage]);

  const getSlicedParagraph = (content, maxLength = 100) => {
    const textContent = content.find((item) => item.type === "text");
    if (textContent) {
      return textContent.data.length > maxLength
        ? textContent.data.slice(0, maxLength) + "..."
        : textContent.data;
    }
    return "Global Care Finder Blogs";
  };

  const getMainImage = (content) => {
    const image = content.find((item) => item.type === "image");
    if (image) {
      return image.data;
    }
    return GlobalCareFinderMainImage;
  };

  return (
    <Box>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.light,
          padding: { lg: "16px", xs: "10px" },
          // background: "#fff"
        }}
      >
        <Box
          sx={{
            maxWidth: "1400px",
            margin: "auto",
            padding: "1em 0em 2em 0em",

            width: "100%",
          }}
        >
          {loading ? (
            <CardSkeleton />
          ) : blogs ? (
            <>
              <Typography
                sx={{
                  textWrap: "wrap",
                  fontSize: "2em",
                  marginBottom: "10px",
                  lineHeight: "45px",
                  fontWeight: "500",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "1.8",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "1.5",
                  },
                }}
                variant="h1"
              >
                Blogs of{" "}
                <span style={{ color: theme.palette.primary.dark }}>
                  Global Care Finder
                </span>
              </Typography>
              <Typography variant="body1" mb={2.5} className="text">
                Explore our blog section for insightful articles, the latest
                healthcare trends, and tips that help you take better care of
                your health. We continuously share updates about the medical
                world, provide tips on finding the right doctor, and highlight
                innovations in healthcare. Our mission is to create a platform
                where both patients and doctors can connect effortlessly,
                ensuring that quality care is always within reach. Stay tuned to
                our blogs and be part of the Global Care Finder community, where
                your health is our priority.
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: {
                    xs: "repeat(auto-fit, minmax(270px, 1fr))",
                    lg: "repeat(auto-fit, minmax(400px, 1fr))",
                  },
                  justifyItems: "center",
                }}
              >
                {blogs?.map((blog, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { xs: "290px", lg: "380px" },
                      width: "100%",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      padding: { lg: ".7em", xs: ".3em" },
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      component="img"
                      src={getMainImage(blog.content)}
                      sx={{
                        width: "100%",
                        height: "270px",
                        objectFit: "cover",
                        marginY: "10px",
                        backgroundColor: "#fff",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: { lg: "10px", xs: "5px" },
                        justifyContent: "space-between",
                        padding: ".5em",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: { lg: "10px", xs: "3px" },
                          alignItems: "center",
                        }}
                      >
                        <PersonIcon
                          color="primary"
                          sx={{ width: "15px", height: "15px" }}
                        />
                        <Typography>By GCF Admin</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: { lg: "10px", xs: "3px" },
                          alignItems: "center",
                        }}
                      >
                        <CalendarTodayIcon
                          color="primary"
                          sx={{ width: "15px", height: "15px" }}
                        />
                        <Typography>
                          {new Date(blog.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flexGrow: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: "25px",
                            fontWeight: "500",
                            margin: "1rem 0rem 1.5rem 0rem",
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "1.2em",
                            },
                          }}
                        >
                          {blog.title.length > 30
                            ? blog.title.slice(0, 30) + "..."
                            : blog.title}
                        </Typography>
                        <Typography variant="body1" mb={1.5} className="text">
                          {getSlicedParagraph(blog.content)}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          width: "130px",
                          marginTop: "auto", // Push the button to the bottom of the card
                        }}
                        onClick={() => {
                          navigate(`/blog/${blog.slug}`);
                        }}
                      >
                        Read More
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>

              {blogs && blogs.length ? (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{
                    backgroundColor: theme.palette.grey[150],
                    color: theme.palette.primary.dark,
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <NoData isTable={false} name="Blogs" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AllBlogs;
