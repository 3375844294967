import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  Card,
  CircularProgress,
} from "@mui/material";
import BlogService from "services/BlogsService";
import useApi from "hooks/useApi";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { AccessTime, Event, Person } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "services/UserService";
import placeholderImage from "assets/images/main/logo.png";

const Blog = () => {
  const { slug } = useParams();
  const isOldId = /^[0-9a-fA-F-]{36}$/.test(slug);
  const navigate = useNavigate();
  const theme = useTheme();
  const getBlogApi = useApi(BlogService.getBlogBySlug);
  const getBlogTitleApi = useApi(BlogService.getBlogById);
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toc, setToc] = useState([]);
  const [totalReadTime, setTotalReadTime] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const tocRef = useRef(null);
  const postContactUsDataApi = useApi(UserService.postContactUsData);
  const latestBlogsApi = useApi(BlogService.getLatestBlogs);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const observerRef = useRef(null);
  const isManualScroll = useRef(false);
  const contactFormRef = useRef(null);
  const sidebarRef = useRef(null);
  const mainContentRef = useRef(null);
  const [isSidebarScrollable, setIsSidebarScrollable] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current && sidebarRef.current) {
        const mainContentRect = mainContentRef.current.getBoundingClientRect();
        const sidebarRect = sidebarRef.current.getBoundingClientRect();

        const shouldBeScrollable = mainContentRect.bottom <= sidebarRect.bottom;
        setIsSidebarScrollable(shouldBeScrollable); // Update state!
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isOldId) {
      fetchBlogContent();
    } else {
      fetchBlogTitle();
    }
    fetchLatestBlogs();
  }, [slug]);

  useEffect(() => {
    // Clear existing observer before setting up new one
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create new observer with better thresholds and options
    observerRef.current = new IntersectionObserver(
      (entries) => {
        // Only process if this is not from a manual scroll
        if (!isManualScroll.current) {
          // Find the most visible section
          const visibleEntries = entries.filter(
            (entry) => entry.isIntersecting
          );

          if (visibleEntries.length > 0) {
            // Sort by visibility ratio and get the most visible
            const mostVisible = visibleEntries.reduce((prev, current) => {
              return prev.intersectionRatio > current.intersectionRatio
                ? prev
                : current;
            });

            setActiveSection(mostVisible.target.id);
          }
        }
      },
      {
        // Root margin to start detecting slightly before the element comes into view
        rootMargin: "-10% 0px -80% 0px",
        // Multiple thresholds for better accuracy
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    // Observe all section elements
    toc.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) {
        observerRef.current.observe(element);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [toc]);

  useEffect(() => {
    if (activeSection && !isManualScroll.current) {
      // Update URL
      const newUrl = `${window.location.pathname}${window.location.search}#${activeSection}`;
      window.history.replaceState(null, "", newUrl);

      // Handle ToC scrolling
      const activeLink = tocRef.current?.querySelector(
        `a[href="#${activeSection}"]`
      );
      if (activeLink && tocRef.current) {
        const linkRect = activeLink.getBoundingClientRect();
        const containerRect = tocRef.current.getBoundingClientRect();

        // Only scroll if the active link is out of view
        if (
          linkRect.top < containerRect.top ||
          linkRect.bottom > containerRect.bottom
        ) {
          activeLink.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }
  }, [activeSection]);

  const handleContactUsClick = () => {
    contactFormRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  // Render ToC with improved click handling
  const renderTableOfContents = () => (
    <ul ref={tocRef} style={{ listStyleType: "none", padding: 0 }}>
      {toc.map((item) => (
        <li
          key={item.id}
          style={{
            marginLeft: `${(item.level - 1) * 10}px`,
            marginBlock: "5px",
          }}
        >
          <a
            href={`#${item.id}`}
            onClick={(e) => handleTocClick(e, item.id)}
            style={{
              fontWeight: item.level === 1 ? "bold" : "normal",
              fontSize: `${16 - item.level}px`,
              color:
                activeSection === item.id
                  ? theme.palette.secondary.main
                  : "inherit",
              textDecoration: activeSection === item.id ? "underline" : "none",
              display: "block",
              padding: "4px 0",
              transition: "all 0.2s ease",
            }}
          >
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  );

  const fetchLatestBlogs = async () => {
    try {
      const response = await latestBlogsApi.request();
      if (response.data && response.data.response?.blogs) {
        setLatestBlogs(response.data.response.blogs.slice(0, 2));
      }
    } catch (error) {
      toast.error("Error fetching latest blogs.");
    }
  };

  const findFirstTextElement = (data, title) => {
    if (title) {
      return title.length > 35 ? `${title.slice(0, 35)}...` : title;
    }
    const element = data.find((element) => element.type === "text");
    if (element) {
      let elementText = element.data;
      return elementText.length > 150
        ? `${elementText.slice(0, 147)}...`
        : elementText;
    }
    return "";
  };

  const findfirstImageElement = (data) => {
    const element = data.find((element) => element.type === "image");
    if (element) {
      return element.data;
    }
    return null;
  };

  const handleBlogClick = (slug) => {
    navigate(`/blog/${slug}`);
  };

  const fetchBlogContent = async () => {
    try {
      const blogData = await getBlogApi.request(slug);
      if (blogData.data.statusCode === 200) {
        setLoading(false);
        setBlog(blogData.data.response);
        getReadTime(blogData.data?.response?.content);
        extractTOC(blogData.data.response);
      }
    } catch (error) {
      setLoading(false);
      setBlog(null);
      toast.error("Error fetching blog data.");
    }
  };

  const fetchBlogTitle = async () => {
    try {
      const blogSlug = await getBlogTitleApi.request(slug);

      if (blogSlug.data.statusCode === 200) {
        setLoading(false);
        const slug = blogSlug.data.response;
        navigate(`/blog/${slug}`);
      }
    } catch (error) {
      setLoading(false);
      setBlog(null);
      toast.error("Error getting signed URL for image upload.");
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is required")
        .max(50, "Name cannot be greater than 50 characters"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      message: Yup.string()
        .required("Message is required")
        .max(800, "Message cannot be greater than 800 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const data = await postContactUsDataApi.request(values);
        if (data.status === 201 || data.status === 200) {
          toast.success(
            "Thank you! Your contact request has been submitted. We will contact you soon."
          );
          resetForm();
          setFormSubmitted(true);
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }
      } catch (error) {
        toast.error("Failed to submit your request. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const getReadTime = (allData) => {
    const allText = allData.reduce((acc, currentInput) => {
      return currentInput?.type === "text"
        ? acc + " " + currentInput.data
        : acc;
    }, "");

    const wordCount = allText.trim().split(/\s+/).length;
    setTotalReadTime(Math.ceil(wordCount / 200));
  };

  const handleTocClick = (e, sectionId) => {
    e.preventDefault();
    isManualScroll.current = true;

    const element = document.getElementById(sectionId);
    if (element) {
      // Smooth scroll to element with offset
      const offset = 80; // Adjust based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      // Update active section
      setActiveSection(sectionId);

      // Reset manual scroll flag after animation
      setTimeout(() => {
        isManualScroll.current = false;
      }, 100);
    }
  };

  const extractTOC = (blogContent) => {
    const headings = [];

    if (blogContent.title) {
      headings.push({ id: "title", text: blogContent.title, level: 1 });
    }

    blogContent.content.forEach((item, index) => {
      if (item.type.startsWith("h") && item.type.length === 2) {
        const level = parseInt(item.type[1]);
        headings.push({
          id: `section-${index}`,
          text: item.data,
          level: level,
        });
      }
    });

    setToc(headings);
  };

  const formattedDate = blog?.createdAt
    ? dayjs(blog.createdAt).format("MMMM D, YYYY")
    : "Unknown Date";

  return (
    <Box
      className="home-page"
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        maxWidth: "1400px",
        margin: "auto",
        paddingY: "2em",
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          flex: 1,
          padding: "1em",
          maxHeight: "fit-content",
          position: "sticky",
          top: "20px",
          ...(isSidebarScrollable && {}),
        }}
        ref={sidebarRef}
      >
        <Card
          variant="outlined"
          sx={{
            padding: "1em",
            marginBottom: "1em",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography
            variant="h3"
            textAlign={"center"}
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Table of Contents
          </Typography>
          {renderTableOfContents()}
        </Card>

        {/* Latest Blogs Section */}
        <Card
          variant="outlined"
          sx={{
            padding: "1em",
            marginBottom: "1em",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Typography
            variant="h3"
            textAlign={"center"}
            sx={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Latest Blogs
          </Typography>
          <Box
            sx={{
              display: "grid",
              gap: "20px",
              gridTemplateColumns: {
                xs: "repeat(auto-fit, minmax(270px, 1fr))",
                lg: "repeat(auto-fit, minmax(290px, 1fr))",
              },
              justifyItems: "center",
            }}
          >
            {latestBlogs.map((blog, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: { xs: "290px", lg: "300px" },
                  width: "100%",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: { lg: ".7em", xs: ".3em" },
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleBlogClick(blog.slug)}
              >
                {/* Blog Image */}
                <Box
                  component="img"
                  src={findfirstImageElement(blog.content) || placeholderImage}
                  sx={{
                    width: "100%",
                    height: "270px",
                    objectFit: "cover",
                    marginY: "10px",
                    backgroundColor: "#fff",
                  }}
                />

                {/* Author and Date Section */}
                <Box
                  sx={{
                    display: "flex",
                    gap: { lg: "10px", xs: "5px" },
                    justifyContent: "space-between",
                    padding: ".5em",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: { lg: "10px", xs: "3px" },
                      alignItems: "center",
                    }}
                  >
                    <Person
                      color="primary"
                      sx={{ width: "15px", height: "15px" }}
                    />
                    <Typography>By {blog.author || "Admin"}</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: { lg: "10px", xs: "3px" },
                      alignItems: "center",
                    }}
                  >
                    <Event
                      color="primary"
                      sx={{ width: "15px", height: "15px" }}
                    />
                    <Typography>
                      {new Date(blog.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>
                </Box>

                {/* Blog Title and Excerpt */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    padding: "1em",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        margin: "0.5rem 0rem 0.5rem 0rem",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "1em",
                        },
                      }}
                    >
                      {blog.title.length > 30
                        ? blog.title.slice(0, 30) + "..."
                        : blog.title}
                    </Typography>
                    <Typography variant="body1" mb={1.5} className="text">
                      {findFirstTextElement(blog.content)}
                    </Typography>
                  </Box>

                  {/* Read More Button */}
                  <Button
                    variant="contained"
                    sx={{
                      width: "130px",
                      marginTop: "auto",
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent card click event from firing
                      handleBlogClick(blog.slug);
                    }}
                  >
                    Read More
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        </Card>

        <Card
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.getContrastText(theme.palette.secondary.main),
            padding: "2em",
            borderRadius: "8px",
            boxShadow: 3,
          }}
          ref={contactFormRef}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              marginBottom: "15px",
              textAlign: "center",
              color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
          >
            Get in Touch
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
          >
            Let Us Help You Find the Perfect Care for Your Loved One!
          </Typography>
          {formSubmitted ? (
            <Typography
              variant="h6"
              sx={{ color: "lightgreen", textAlign: "center" }}
            >
              Thank you for contacting us!
            </Typography>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Name"
                fullWidth
                variant="filled"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                required
                sx={{
                  marginTop: "15px",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.95)",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                    "&:before": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                    "&:hover:before": {
                      borderColor: theme.palette.secondary.main,
                    },
                    "& input": {
                      color: theme.palette.primary.dark,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.primary.dark,
                    "&.Mui-focused": {
                      color: theme.palette.secondary.main,
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.main,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    margin: 0,
                    padding: "4px 8px",
                  },
                }}
              />
              <TextField
                label="Email"
                type="email"
                fullWidth
                variant="filled"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
                sx={{
                  marginTop: "15px",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.95)",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                    "&:before": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                    "&:hover:before": {
                      borderColor: theme.palette.secondary.main,
                    },
                    "& input": {
                      color: theme.palette.primary.dark,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.primary.dark,
                    "&.Mui-focused": {
                      color: theme.palette.secondary.main,
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.main,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    margin: 0,
                    padding: "4px 8px",
                  },
                }}
              />
              <TextField
                label="Message"
                multiline
                rows={4}
                fullWidth
                variant="filled"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                required
                sx={{
                  marginTop: "15px",
                  "& .MuiFilledInput-root": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.95)",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                    "&:before": {
                      borderColor: "rgba(255, 255, 255, 0.7)",
                    },
                    "&:hover:before": {
                      borderColor: theme.palette.secondary.main,
                    },
                    "& input": {
                      color: theme.palette.primary.dark,
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.primary.dark,
                    "&.Mui-focused": {
                      color: theme.palette.secondary.main,
                    },
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.main,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    margin: 0,
                    padding: "4px 8px",
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                disabled={loading}
                sx={{
                  marginTop: "20px",
                  padding: "12px",
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.secondary.contrastText,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size="25px" />
                ) : (
                  "Send"
                )}
              </Button>
            </form>
          )}
        </Card>
      </Box>
      {/* Main Content */}
      <Box sx={{ flex: 3, padding: "1em" }} ref={mainContentRef}>
        {blog && (
          <>
            <Typography
              variant="h1"
              id="blog-title"
              sx={{ fontSize: "30px", fontWeight: "bold" }}
            >
              {blog.title}
            </Typography>

            {/* Author, Date, Read Time Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: { sm: "flex-start" }, // Align items to start in desktop
                gap: { xs: "12px", sm: "16px" }, // Reduced gap for desktop
                color: "text.secondary",
                my: "0.5em",
                padding: { xs: "12px", sm: "8px 16px" },
                backgroundColor: "background.paper",
                borderRadius: "12px",
                boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                border: "1px solid #f0f0f0",
                transition: "all 0.3s ease-in-out",
                ":hover": {
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  backgroundColor: "background.default",
                },
              }}
            >
              {/* Author */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Person
                  fontSize="small"
                  sx={{ transition: "color 0.2s ease" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    transition: "color 0.2s ease",
                    ":hover": { color: "primary.main" },
                    fontSize: { xs: "0.8rem", sm: "0.875rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {blog.author || "Admin"}
                </Typography>
              </Box>

              {/* Date */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Event
                  fontSize="small"
                  sx={{ transition: "color 0.2s ease" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    transition: "color 0.2s ease",
                    ":hover": { color: "primary.main" },
                    fontSize: { xs: "0.8rem", sm: "0.875rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {formattedDate}
                </Typography>
              </Box>

              {/* Read Time */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <AccessTime
                  fontSize="small"
                  sx={{ transition: "color 0.2s ease" }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    transition: "color 0.2s ease",
                    ":hover": { color: "primary.main" },
                    fontSize: { xs: "0.8rem", sm: "0.875rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {totalReadTime ? `${totalReadTime} min read` : "5 min read"}
                </Typography>
              </Box>
            </Box>

            {blog.content.map((item, index) =>
              item.type.startsWith("h") ? (
                <Typography
                  id={`section-${index}`}
                  variant={item.type}
                  sx={{
                    marginBlock: "2rem",
                    fontWeight: item.type === "h1" ? "bold" : "normal",
                  }}
                >
                  {item.data}
                </Typography>
              ) : item.type === "text" ? (
                <Typography
                  sx={{ marginBottom: "1rem", fontSize: "1rem" }}
                  variant="body1"
                >
                  {item.data}
                </Typography>
              ) : item.type === "image" ? (
                <img
                  src={item.data}
                  alt="Blog Visual"
                  style={{ width: "100%", height: "auto", margin: "10px 0" }}
                />
              ) : null
            )}

            <Box sx={{ marginTop: "2em" }}>
              <Button
                variant="contained"
                sx={{
                  padding: "12px 24px",
                  fontSize: "16px",
                }}
                onClick={handleContactUsClick}
              >
                Contact Us Today
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Blog;
